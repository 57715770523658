import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteFeedback } from "src/DAL/SendFormTemplate/sendFormTemplate";
import RecordNotFound from "src/components/RecordNotFound";
import { show_proper_words } from "src/utils/constant";
import moment from "moment";

const FeedBack = ({
  feedbackList,
  setFeedbackList,
  setOpenAddFeedback,
  setFeedback,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [feedbackId, setFeedbackId] = useState("");

  const handleEdit = (value) => {
    setFeedback({
      feedback: value?.feedback_content,
      feedbackId: value?._id,
    });
    setOpenAddFeedback(true);
  };

  const handleDelete = (value) => {
    setOpenDelete(true);
    setFeedbackId(value?._id);
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteFeedback(feedbackId);
    if (result?.code === 200) {
      setOpenDelete(false);
      setFeedbackList((prev) =>
        prev.filter((item) => item?._id !== feedbackId)
      );
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "mdi:delete",
      handleClick: handleDelete,
    },
  ];

  return (
    <div className="container">
      <div className="row">
        {feedbackList.length > 0 ? (
          feedbackList?.map((item, index) => {
            return (
              <div className="col-12 mt-2">
                <Card
                  className="w-100"
                  sx={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    mb: 1,
                    width: { sm: "100%", md: "100%" },
                    margin: "auto",
                    p: 2,
                    backgroundColor: "",
                    borderRadius: 0.5,
                  }}
                >
                  <Accordion defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <p className="mb-0">
                        {index + 1} : {show_proper_words(item.feedback_type)}{" "}
                        Feedback
                      </p>{" "}
                      <div
                        className="ms-auto d-flex"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <span className="text-muted">
                          {moment(item?.createdAt).format(
                            "DD MMM YYYY [at] hh:mm A"
                          )}
                        </span>{" "}
                        <CustomPopoverSection menu={MENU_OPTIONS} data={item} />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.feedback_content,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Card>
              </div>
            );
          })
        ) : (
          <RecordNotFound title="Feedback" />
        )}
      </div>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete Feedback ?"}
        handleAgree={handleDeleteConfirm}
      />
    </div>
  );
};

export default FeedBack;
