import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import { editForm_V1 } from "src/DAL/SendFormTemplate/sendFormTemplate";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [allTemplates, setAllTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    status: true,
    description: "",
    title: "",
    form_template: "",
    is_recurring: false,
    start_date: null,
    recurring_form_days: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      title: inputs?.title,
      form_template: inputs?.form_template,
      description: inputs?.description,
      status: inputs?.status,
      is_recurring: inputs?.is_recurring,
    };

    if (inputs.is_recurring) {
      postData.start_date = moment(inputs?.start_date).format("YYYY-MM-DD");
      postData.recurring_form_days = inputs?.recurring_form_days;
    }

    const res = await editForm_V1(state.form_slug, postData);
    if (res.code === 200) {
      navigate(-1);
      enqueueSnackbar(res.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "is_recurring") {
      setInputs((values) => ({
        ...values,
        start_date: null,
        recurring_form_days: null,
      }));
    }
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getFormListing = async () => {
    setIsLoading(true);
    const result = await getAllTemplatesListByType(state?.receiver_type);
    if (result.code === 200) {
      setAllTemplates(result?.form_templates);
      setInputs((value) => ({
        ...value,
        title: state.title,
        form_template: state.form_template,
        description: state.description,
        status: state?.status,
        start_date: state?.start_date,
        is_recurring: state?.is_recurring,
        recurring_form_days: state?.recurring_form_days,
      }));
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const isDateDisabled = (date) => {
    let currentDate = moment().format("YYYY-MM-DD");
    let selectedDate = moment(date).format("YYYY-MM-DD");
    return selectedDate < currentDate;
  };

  useEffect(() => {
    getFormListing();
  }, [state]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex align-items-center">
          <IconButton
            className="back-screen-button mb-0"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="mb-0 ms-2">Edit Form</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl sx={{ width: "100%" }} required>
              <InputLabel id="demo-simple-selec-label">
                Form Template
              </InputLabel>
              <Select
                required
                labelId="demo-simple-selec-label"
                id="demo-simple-select"
                name="form_template"
                value={inputs?.form_template}
                label="Form Template"
                onChange={handleChange}
              >
                {allTemplates.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs?.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Recurring Forms?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_recurring"
                value={inputs.is_recurring}
                label="Recurring Forms?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_recurring && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DatePicker
                    label="Start Date"
                    name="start_date"
                    inputFormat="dd-MM-yyyy"
                    value={inputs.start_date}
                    disablePast
                    disabled={isDateDisabled(inputs.start_date)}
                    onChange={(e) => {
                      handleSelectOther("start_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <Tooltip
                        title={
                          isDateDisabled(inputs.start_date)
                            ? "Date cannot be updated because it has already passed"
                            : ""
                        }
                      >
                        <TextField
                          {...params}
                          className="inputs-fields"
                          fullWidth
                          required
                          disabled={isDateDisabled(inputs.start_date)}
                        />
                      </Tooltip>
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No Of Recurring Days"
                  variant="outlined"
                  required
                  type="number"
                  sx={{ width: "100%" }}
                  name="recurring_form_days"
                  value={inputs.recurring_form_days}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Description"
              multiline
              variant="outlined"
              sx={{ width: "100%" }}
              rows={4}
              required
              name="description"
              value={inputs.description}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
