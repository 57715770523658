import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";

export default function UserFormFilterchild({
  filterData,
  handleSelectOther,
  searchFunction,
  handleClearFilter,
}) {
  const goalsLevel = [
    {
      name: "Submitted",
      value: "submitted",
    },
    {
      name: "Pending",
      value: "pending",
    },
  ];

  return (
    <>
      <div className="container new-memories px-4">
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">
            Submition Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.submission_status}
            name="submition_status"
            label="Submition Status"
            onChange={(e) =>
              handleSelectOther("submission_status", e.target.value)
            }
          >
            {goalsLevel?.map((goal) => {
              return (
                <MenuItem value={goal.value} key={goal.value}>
                  {goal.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterData.filter_by_dates}
                onChange={(e) => {
                  handleSelectOther("filter_by_dates", e.target.checked);
                }}
              />
            }
            label="Search By Date Range"
          />
          {filterData.filter_by_dates && (
            <>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date *"
                    name="date_from"
                    inputFormat="dd-MM-yyyy"
                    value={filterData.date_from}
                    onChange={(e) => {
                      handleSelectOther("date_from", e);
                    }}
                    required
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date *"
                    name="date_to"
                    value={filterData.date_to}
                    inputFormat="dd-MM-yyyy"
                    required
                    onChange={(e) => {
                      handleSelectOther("date_to", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
        </>
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchFunction}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
