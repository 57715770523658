import { invokeApi } from "src/bl_libs/invokeApi";

export const getAllFormsList = async (page, rows, search) => {
  const requestObj = {
    path: `api/form/form_list?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const getPreviewDetail = async (id) => {
  const requestObj = {
    path: `api/form/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getAllFormsListV1 = async (page, rows, data) => {
  const requestObj = {
    path: `api/form/form_list/v1?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteForm_future_scheduled = async (_id) => {
  const requestObj = {
    path: `api/form/delete_overall/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addFeedback = async (data) => {
  const requestObj = {
    path: `api/form/add_feedback`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editFeedback = async (data) => {
  const requestObj = {
    path: `api/form/edit_feedback`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteFeedback = async (id) => {
  const requestObj = {
    path: `api/form/delete_feedback/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getTemplateDetail_V1 = async (id, user_id, type, option) => {
  const requestObj = {
    path: `api/form/list_feedback?form_id=${id}&feedback_type=${type}${
      user_id ? `&member_id=${user_id}` : ""
    }${option ? `&type=${option}` : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getFormMembersDetails_V1 = async (id, user_id) => {
  const requestObj = {
    path: `api/form/get_answers_or_questions?form_id=${id}&user_id=${user_id}&feedback_type=individual`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getAllFormsMembersList = async (slug, page, rows, search) => {
  const requestObj = {
    path: `api/form/detail/v1/${slug}?&page=${page}&limit=${rows}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_form_member_v1 = async (id, _id) => {
  const requestObj = {
    path: `api/form/delete_member_from_form/${id}/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getAllFormsList_members_and_coach = async (
  page,
  rows,
  data,
  id
) => {
  const requestObj = {
    path: `api/form/form_list_for_member_by_admin?user_id=${id}&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getAllTemplatesListByType = async (search) => {
  const requestObj = {
    path: `api/form_template/all_form_template?search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getAllMembersFormsAgainstProgramsList = async (
  page,
  rows,
  search
) => {
  const requestObj = {
    path: `api/form/get_members_against_program_form_template?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const getTemplateDetail = async (id) => {
  const requestObj = {
    path: `api/form_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addForm = async (data) => {
  const requestObj = {
    path: `api/form`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editForm = async (_id, data) => {
  const requestObj = {
    path: `api/form/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editForm_V1 = async (_id, data) => {
  const requestObj = {
    path: `api/form/update_form/v1/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const recursive_form_list = async (page, rows, data) => {
  const requestObj = {
    path: `api/form/recursive_form_list?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteForm_v1 = async (_id) => {
  const requestObj = {
    path: `api/form/delete_form/v1/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteForm = async (_id) => {
  const requestObj = {
    path: `api/form/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteFormForSpecificMember = async (_id) => {
  const requestObj = {
    path: `api/form/delete_form/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTemplatesWithoutSearchAndInActive = async (data) => {
  const requestObj = {
    path: `api/feedback_template/get_feedback_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
