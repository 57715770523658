import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import Label from "src/components/Label";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  delete_form_member_v1,
  getAllFormsMembersList,
} from "src/DAL/SendFormTemplate/sendFormTemplate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomMUITable from "src/components/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UserFormMembers({ type }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [userDetails, setUserDetails] = useState({});

  const getFormMembersList = async (search) => {
    setIsLoading(true);
    const result = await getAllFormsMembersList(id, page, rowsPerPage, search);
    if (result.code === 200) {
      let data = result.members.map((item) => {
        let fullName = "N/A";
        let submitted_at = "N/A";
        if (item?.details) {
          fullName = item?.details?.first_name + " " + item?.details?.last_name;
        }

        if (item?.submission_date) {
          submitted_at = moment(item?.submission_date).format(
            "ddd, MMM DD, YYYY"
          );
        }

        return {
          ...item,
          fullName,
          submitted_at,
        };
      });
      setUserDetails(result?.form);
      setTotalPages(result?.total_pages);
      setTotalCount(result?.total_members);
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getFormMembersList(searchText);
  };

  const handlePreview = (value) => {
    if (type === "programmes") {
      navigate(
        `/programmes/members/forms-preview/${userDetails?._id}/${value.user_id}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/form-list/forms/members/forms-preview/${userDetails?._id}/${value.user_id}`,
        {
          state: value,
        }
      );
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_form_member_v1(id, deleteDoc.user_id);
    if (result.code === 200) {
      setUserList(
        userList.filter((item) => item.user_id !== deleteDoc.user_id)
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "fullName", label: "Member" },
    { id: "submitted_at", label: "Submitted At" },
    {
      id: "submission_status",
      label: "Submission Status",
      renderData: (data) => {
        return (
          <Label
            variant="ghost"
            color={
              (data.submission_status === "submitted" && "success") || "warning"
            }
          >
            {data.submission_status === "submitted" ? "Submitted" : "Pending"}
          </Label>
        );
      },
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Preview",
      icon: "pajamas:eye",

      handleClick: handlePreview,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getFormMembersList(searchText);
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12 col-md-8 d-flex align-items-baseline">
          <IconButton
            className="back-screen-button mb-0"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <div className="ms-2">
            <h2>{userDetails?.title} </h2>
            <p className="mb-0">{userDetails?.description} </p>
            <span className="date-color">
              {moment(userDetails?.createdAt).format("ddd, MMM DD, YYYY")}
            </span>
          </div>
        </div>
        <div className="col-12 mt-4">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
            rows_per_page_count={rowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
