import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import Label from "src/components/Label";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  deleteForm,
  getAllFormsList_members_and_coach,
  getAllFormsListV1,
} from "src/DAL/SendFormTemplate/sendFormTemplate";
import CustomMUITable from "src/components/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ListUserForm({ type }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const getFormTemListing = async (search) => {
    setIsLoading(true);
    let postData = {
      search: search,
    };
    const result = await getAllFormsList_members_and_coach(
      page,
      rowsPerPage,
      postData,
      id
    );
    if (result.code === 200) {
      let data = result.forms.map((item) => {
        let data = "N/A";
        if (item.createdAt) {
          data = moment(item.createdAt).format("ddd, MMM DD, YYYY");
        }
        return { ...item, MENU_OPTIONS: handleMenu(item), created_at: data };
      });
      setUserList(data);
      setTotalCount(result.total_forms);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handlePreview = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/${type}/forms-preview/${value._id}/${id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteForm(deleteDoc.form_slug);
    if (result.code === 200) {
      getFormTemListing(searchText);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("member_user_form_search", searchText);
    getFormTemListing(searchText);
  };

  const handleMenu = (item) => {
    const MENU_OPTIONS = [
      {
        label: "Preview",
        icon: "pajamas:eye",
        handleClick: handlePreview,
      },
    ];
    if (item.submission_status === "submitted") {
      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      });
    }
    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "created_at", label: "Date" },
    { id: "title", label: " Title" },
    {
      id: "submission_status",
      label: "Submission Status",
      renderData: (data) => {
        return (
          <Label
            variant="ghost"
            color={
              data.submission_status === "submitted" ? "success" : "warning"
            }
          >
            {data.submission_status === "submitted" ? `Submitted` : "Pending"}
          </Label>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (data) => (
        <Label
          variant="ghost"
          color={data.status === false ? "error" : "success"}
        >
          {data.status === false ? "InActive" : "Active"}
        </Label>
      ),
    },
  ];

  useEffect(() => {
    let find_filter = localStorage.getItem("member_user_form_search");
    if (find_filter) {
      setSearchText(find_filter);
    }
    getFormTemListing(find_filter);
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-12 d-flex">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h2 className="mb-0 ms-2">
              {state?.fulName ? state?.fulName : "Forms"}
            </h2>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
            rows_per_page_count={rowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
