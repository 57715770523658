import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { useContentSetting } from "./ContentSettingState";
import {
  _check_chat_existing,
  _get_chat_history,
  _get_chat_message,
  _read_message,
} from "src/DAL/Chat/Chat";
import { is_small_screen } from "src/utils/constants";
import ChatBox from "src/pages/Chat/ChatBox";

const CreateChatContext = React.createContext();

export const useChat = () => useContext(CreateChatContext);
export function ChatContext({ children }) {
  const { userInfo, socket, handleUnreadMessageCount } = useContentSetting();
  const observer = useRef();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  /* ------------------------------------------------------
  ---------------------------| States |--------------------
  ------------------------------------------------------- */
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [checkLoadMore, setCheckLoadMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [count, setCount] = useState(0);
  const [chatLoading, setChatLoading] = useState(true);
  const [hasPageNumber, setHasPageNumber] = useState(0);
  const [hasTotalPages, setHasTotalPages] = useState(false);
  const [hasMorePath, setHasMorePath] = useState(false);
  const [hasLoading, setHasLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedChatUser, setSelectedChatUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messagesList, setMessagesList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [coachMember, setCoachMember] = useState([]);
  const [unreadChatCounts, setUnreadChatCounts] = useState(0);
  const [chatList, setChatList] = useState([]);

  /* ------------------------------------------------------
  -------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleSetEventSlug = (value) => {
    setEventSlug(value);
  };

  const handleSetSelectedChat = (value) => {
    setSelectedChatUser(value.receiver_profile);
    setSelectedChat(value);
  };

  const handleCloseChat = () => {
    setSelectedChat(null);
    setSelectedChatUser(null);
    setSelectedUser(null);
  };

  const get_profile_from_member = (member_id, members = []) => {
    const member_profile = members.find((member) => member.id == member_id);
    return member_profile;
  };

  const get_receiver_profile = (members = []) => {
    const profile = members.find((m) => m.id !== userInfo._id);
    return { ...profile };
  };

  const get_formatted_message = (message_obj) => {
    const is_self = userInfo._id === message_obj.sender.id ? true : false;
    return { ...message_obj, is_self };
  };

  const get_chat_list = async (path, search, loading) => {
    if (!loading) {
      path = `api/chat/list_chat_by_user?page=0&limit=30`;
    }

    const result = await _get_chat_history(path, search);
    if (result.code === 200) {
      setHasMorePath(result.load_more);
      setHasTotalPages(result.hasNextPage);
      setUnreadChatCounts(result.total_unread_chats);
      setCoachMember(result.coach_members);

      const _chats = result.chats.map((chat) => {
        const receiver_profile = get_receiver_profile(chat?.members);
        const my_profile = get_profile_from_member(userInfo._id, chat?.members);
        return {
          ...chat,
          receiver_profile,
          my_profile,
        };
      });

      if (loading) {
        setChatList([...chatList, ..._chats]);
        setHasPageNumber((prev) => prev + 1);
      } else {
        setHasPageNumber(0);
        setChatList(_chats);
      }

      setChatLoading(false);
      setHasLoading(false);
    } else {
      setChatLoading(false);
      setHasLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const mark_as_read = async (chat_id, value) => {
    const result = await _read_message(chat_id);
    if (result.code === 200) {
      if (value) {
        get_chat_list();
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_all_unread_count = () => {
    let count = 0;
    if (chatList.length > 0) {
      chatList.forEach((chat) => {
        if (chat.my_profile?.unread_message_count) {
          count += chat.my_profile?.unread_message_count;
        }
      });
      handleUnreadMessageCount(count);
    }
    return count;
  };

  const scroll_to_bottom_on_message = () => {
    const chat_box = document.getElementsByClassName("user-chat-box-body")[0];
    chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!isLoadingChat && checkLoadMore && !isLoadingMore) {
            loadMoreData(loadMore);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, count, loadMore, observer, messagesList, isLoadingChat]
  );

  const loadMoreChat = async (path) => {
    setIsLoadingMore(true);
    const result = await _get_chat_message(path);
    if (result.code === 200) {
      const formatted_messages = result.messages.map((message_obj) =>
        get_formatted_message(message_obj)
      );
      let scrollBottom = 0;
      let chat_box = document.getElementById("chat_box_body");
      if (chat_box) {
        scrollBottom = chat_box.scrollHeight - chat_box.scrollTop;
      }
      setMessagesList((c) => [...formatted_messages, ...c]);
      setCount(result?.total_coach_count);
      setLoadMore(result?.load_more);
      setCheckLoadMore(result?.hasNextPage);
      setIsLoadingMore(false);
      chat_box.scrollTop = chat_box.scrollHeight - scrollBottom;
    } else {
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleGetChat = async (chat_id) => {
    const first_load_url = `api/chat/list_messages_by_chat/${chat_id}?page=0&limit=50`;
    setIsLoadingChat(true);
    const result = await _get_chat_message(first_load_url);
    if (result.code === 200) {
      const formatted_messages = result.messages.map((message_obj) =>
        get_formatted_message(message_obj)
      );
      setMessagesList(formatted_messages);
      setCount(result?.total_coach_count);
      setLoadMore(result?.load_more);
      setCheckLoadMore(result?.hasNextPage);
      setIsLoadingChat(false);
      setIsLoadingMore(false);
      mark_as_read(chat_id, true);
      let chat_box = document.getElementById("chat_box_body");
      chat_box.scrollTop = chat_box.scrollHeight;
    } else {
      setIsLoadingChat(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    loadMoreChat(loadMore);
  };

  const handleAddChat = async (user_id, handleCloseDrawer = () => {}) => {
    let already_exist = chatList.find((chat) =>
      chat?.members.some((user) => user.id == user_id)
    );

    console.log(already_exist, "already_exist");
    if (already_exist) {
      handleSetSelectedChat(already_exist);
      handleCloseDrawer();
      return;
    } else {
      const result = await _check_chat_existing(user_id);
      let selected_obj = {};
      if (result.code === 200 && !already_exist) {
        let my_profile = {
          name: userInfo.first_name + " " + userInfo.last_name,
          profileImage: userInfo?.profile_image,
          unread_message_count: 0,
          isOnline: true,
          id: userInfo._id,
        };
        let receiver_profile = {
          name: result.memberInfo.name,
          profileImage: result.memberInfo.profile_image,
          isOnline: result.memberInfo.is_online,
          unread_message_count: 0,
          id: result.memberInfo.id,
        };
        selected_obj = {
          chatId: result.chatId,
          my_profile,
          receiver_profile,
          members: [my_profile, receiver_profile],
        };
      }
      setChatList((prev) => {
        return [selected_obj, ...prev];
      });
      handleSetSelectedChat(selected_obj);
    }
    handleCloseDrawer();
  };

  const receive_new_message = (data) => {
    const new_message = get_formatted_message(data);
    get_chat_list();
    if (
      (selectedChat && data.chat?._id === selectedChat?.chatId) ||
      (selectedChatUser && data.sender.id === selectedChatUser._id)
    ) {
      setMessagesList((old_messages) => [...old_messages, new_message]);
      scroll_to_bottom_on_message();
      mark_as_read(data.chat?._id);
    }
  };

  const handle_edit_message = async (data) => {
    setMessagesList((current_list) => {
      const updated_list = current_list.map((message_obj) => {
        if (message_obj.messageId === data.messageId) {
          return {
            ...message_obj,
            content: data.content,
            image: data.image,
          };
        } else {
          return message_obj;
        }
      });
      return updated_list;
    });

    if (data.chat?.last_message) {
      let new_obj = {
        chatId: data.chat?._id,
        lastMessage: data.chat?.last_message,
        lastMessageDateTime: data.chat?.last_message_date_time,
      };

      setChatList((prev) => {
        return prev.map((chat) => {
          if (data.chat?._id == chat?.chatId) {
            return {
              ...chat,
              ...new_obj,
            };
          } else {
            return chat;
          }
        });
      });
    }
  };

  const handle_delete_message = async (data) => {
    setMessagesList((current_list) => {
      const updated_list = current_list.filter(
        (message_obj) => message_obj.messageId !== data.message_id
      );
      return updated_list;
    });

    get_chat_list();
  };

  useEffect(() => {
    get_all_unread_count();
  }, [chatList]);

  useEffect(() => {
    if (!pathname.match("/chat" || "/chat/")) {
      handleCloseChat();
      setHasPageNumber(0);
      setHasMorePath("api/chat/list_chat_by_user?page=0&limit=30");
    }
  }, [pathname]);

  useEffect(() => {
    setSelectedMessage(null);
    setMessagesList([]);
    setCount(0);
    if (selectedChat) {
      if (selectedChat?.chatId) {
        handleGetChat(selectedChat?.chatId);
      } else {
        setMessagesList([]);
        setCount(0);
      }
    }

    socket.on("send_chat_message_receiver", (data) => {
      console.log(data, "send_chat_message_receiver");
      receive_new_message(data);
    });
    socket.on("update_chat_message_receiver", (data) => {
      console.log(data, "update_chat_message_receiver");
      handle_edit_message(data);
    });
    socket.on("delete_chat_message_receiver", (data) => {
      console.log(data, "delete_chat_message_receiver");
      handle_delete_message(data);
    });

    return () => {
      socket.off("send_chat_message_receiver");
      socket.off("update_chat_message_receiver");
      socket.off("delete_chat_message_receiver");
    };
  }, [selectedChat, socket]);

  const collection = {
    get_chat_list,
    handleAddChat,
    handleCloseChat,
    handleSetEventSlug,
    handleSetSelectedChat,
    mark_as_read,
    setChatList,
    setSelectedChat,
    loadMoreData,
    lastBookElementRef,
    setSelectedMessage,
    setMessagesList,
    setCount,
    isLoadingMore,
    checkLoadMore,
    chatList,
    messagesList,
    selectedChat,
    selectedUser,
    selectedMessage,
    selectedChatUser,
    unreadChatCounts,
    isLoadingChat,
    chatLoading,
    hasMorePath,
    hasPageNumber,
    hasTotalPages,
    hasLoading,
    setHasLoading,
    loadMore,
    coachMember,
  };

  return (
    <CreateChatContext.Provider value={collection}>
      {children}
      {selectedChat && is_small_screen() && (
        <div className="user-chat-box-wrapper">
          <ChatBox
            selectedUser={selectedUser}
            selectedChat={selectedChat}
            handleCloseChat={handleCloseChat}
          ></ChatBox>
        </div>
      )}
    </CreateChatContext.Provider>
  );
}
