import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import Label from "src/components/Label";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getAllProgramFormsList } from "src/DAL/Programme/Programme";
import CustomMUITable from "src/components/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgramUsersForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const getFormTemListing = async (search) => {
    setIsLoading(true);
    let postData = {
      program: id,
      search: search,
    };
    const result = await getAllProgramFormsList(page, rowsPerPage, postData);
    if (result.code === 200) {
      let data = result.forms.map((item) => {
        let data = "N/A";
        if (item.createdAt) {
          data = moment(item.createdAt).format("ddd, MMM DD, YYYY");
        }
        return { ...item, MENU_OPTIONS: handleMenu(item), created_at: data };
      });
      setUserList(data);
      setTotalCount(result.total_forms);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleProgramMembers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/programmes/members/${value.form_slug}`, {
      state: value,
    });
  };

  const handlePreview = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/programmes/programmes-form/forms-preview/${value._id}`, {
      state: value,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("program_user_form_search", searchText);
    getFormTemListing(searchText);
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "created_at", label: "Date" },
    { id: "title", label: " Title" },
    {
      id: "submission_status",
      label: "Submission Status",
      renderData: (data) => {
        return (
          <Label
            variant="ghost"
            color={
              data.submission_status === "submitted" ? "success" : "warning"
            }
          >
            {data.submission_status === "submitted"
              ? `Submitted (${
                  data.submitted_count +
                  "/" +
                  (data.submitted_count + data.pending_count)
                })`
              : "Pending"}
          </Label>
        );
      },
    },
  ];

  const handleMenu = () => {
    let MENU_OPTIONS = [];
    MENU_OPTIONS.push({
      label: "Preview",
      icon: "pajamas:eye",
      handleClick: handlePreview,
    });
    MENU_OPTIONS.push({
      label: "Members",
      icon: "gravity-ui:persons",
      handleClick: handleProgramMembers,
    });
    return MENU_OPTIONS;
  };

  useEffect(() => {
    let find_filter = localStorage.getItem("program_user_form_search");
    if (find_filter) {
      setSearchText(find_filter);
    }
    getFormTemListing(find_filter);
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h2 className="mb-0 ms-2">Form</h2>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
            rows_per_page_count={rowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
